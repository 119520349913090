import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    SearchState,
    articlesSuggestedContentAdapter,
    coursesSuggestedContentAdapter,
    playersSuggestedContentAdapter,
    videoGuidesSuggestedContentAdapter
} from '../reducers/search.reducer';
import { mapCoursesMetadata } from './courses.selectors';
import {
    selectCoursesProgressMap,
    selectProgressVideosMap,
    selectSeenVideosMap
} from './meta.selectors';
import { selectRedeemableItemsCountByType, selectRedeemedItemIds } from './referral.selectors';
import { mapVideoGuidesMetadata } from './shared-content.selectors';
import { selectIsPremium } from './user.selectors';

const selectSearchState = createFeatureSelector<SearchState>('search');
const suggestedVideoGuidesSelectors = videoGuidesSuggestedContentAdapter.getSelectors();
const suggestedPlayersSelectors = playersSuggestedContentAdapter.getSelectors();
const suggestedCoursesSelectors = coursesSuggestedContentAdapter.getSelectors();
const suggestedArticlesSelectors = articlesSuggestedContentAdapter.getSelectors();

export const selectAutocompleteSearchResults = createSelector(
    selectSearchState,
    (state: SearchState) => state.autocompleteSearchResults
);
export const selectSuggestedContentSubstate = createSelector(
    selectSearchState,
    (state: SearchState) => state.suggestedContent
);

export const selectSuggestedVideoGuidesSubstate = createSelector(
    selectSuggestedContentSubstate,
    state => state.videoGuides
);
export const selectSuggestedVideoGuides = createSelector(
    selectSuggestedVideoGuidesSubstate,
    suggestedVideoGuidesSelectors.selectAll
);
export const selectSuggestedPlayersSubstate = createSelector(
    selectSuggestedContentSubstate,
    state => state.players
);
export const selectSuggestedPlayers = createSelector(
    selectSuggestedPlayersSubstate,
    suggestedPlayersSelectors.selectAll
);
export const selectSuggestedCoursesSubstate = createSelector(
    selectSuggestedContentSubstate,
    state => state.courses
);
export const selectSuggestedArticlesSubstate = createSelector(
    selectSuggestedContentSubstate,
    state => state.articles
);
export const selectSuggestedCourses = createSelector(
    selectSuggestedCoursesSubstate,
    suggestedCoursesSelectors.selectAll
);
export const selectSuggestedArticles = createSelector(
    selectSuggestedArticlesSubstate,
    suggestedArticlesSelectors.selectAll
);
export const selectSuggestedContent = createSelector(
    selectSuggestedVideoGuides,
    selectSuggestedPlayers,
    selectSuggestedCourses,
    selectSuggestedArticles,
    selectSeenVideosMap,
    selectProgressVideosMap,
    selectCoursesProgressMap,
    selectIsPremium,
    selectRedeemableItemsCountByType,
    selectRedeemedItemIds,
    (
        suggestedVideoGuides,
        suggestedPlayers,
        suggestedCourses,
        suggestedArticles,
        seenVideosMap,
        progressVideosMap,
        coursesProgressMap,
        isPremium,
        giveawayData: any,
        redeemedItemIds
    ) => ({
        videoGuides: mapVideoGuidesMetadata(
            suggestedVideoGuides,
            seenVideosMap,
            progressVideosMap,
            isPremium,
            redeemedItemIds
        ),
        courses: mapCoursesMetadata(
            suggestedCourses,
            coursesProgressMap,
            seenVideosMap,
            giveawayData,
            redeemedItemIds
        ),
        players: suggestedPlayers.map(p => {
            return {
                ...p,
                videoGuides: mapVideoGuidesMetadata(
                    p.videoGuides,
                    seenVideosMap,
                    progressVideosMap,
                    isPremium,
                    redeemedItemIds
                )
            };
        }),
        articles: suggestedArticles
    })
);
export const selectSuggestedContentExists = createSelector(
    selectSuggestedContent,
    suggestedContent => {
        return (
            suggestedContent?.courses?.length > 0 ||
            suggestedContent?.videoGuides?.length > 0 ||
            suggestedContent?.players?.length > 0 ||
            suggestedContent?.articles?.length > 0
        );
    }
);

// const selectCoursesSubstateState = createSelector(
//     selectCoursesState,
//     (state: CoursesState) => state.courses
// );
// const selectEnrolledCoursesSubstateState = createSelector(
//     selectCoursesState,
//     (state: CoursesState) => state.enrolledCourses
// );
// const selectExtendedCoursesSubstateState = createSelector(
//     selectCoursesState,
//     (state: CoursesState) => state.extendedCourses
// );
// const selectCoursesState = createFeatureSelector<MemoizedSelector<GlobalState, any>>(
//     'courses'
// ).projector((state: GlobalState) => state.courses);

// const coursesSelectors = coursesAdapter.getSelectors();
// const enrolledCoursesSelectors = enrolledCoursesAdapter.getSelectors();
// const extendedCoursesSelectors = extendedCoursesAdapter.getSelectors();

// export const selectAllCourses = createSelector(
//     selectCoursesSubstateState,
//     coursesSelectors.selectAll
// );
// export const selectAllEnrolledCourses = createSelector(
//     selectEnrolledCoursesSubstateState,
//     enrolledCoursesSelectors.selectAll
// );
// export const selectAllExtendedCourses = createSelector(
//     selectExtendedCoursesSubstateState,
//     enrolledCoursesSelectors.selectAll
// );

// export const selectAllCoursesWithProgress = createSelector(
//     selectAllCourses,
//     selectCoursesProgressMap,
//     mapCoursesMetadata
// );
// export const selectAllEnrolledCoursesWithProgress = createSelector(
//     selectAllEnrolledCourses,
//     selectCoursesProgressMap,
//     mapCoursesMetadata
// );
// export const selectAllExtendedCoursesWithProgress = createSelector(
//     selectAllExtendedCourses,
//     selectCoursesProgressMap,
//     mapCoursesMetadata
// );
// export const selectFeaturedCourses = createSelector(
//     selectAllCoursesWithProgress,
//     (courses: CourseViewModel[]) => {
//         return courses.filter(c => c.isFeatured);
//     }
// );
// export const selectHeroCourses = createSelector(
//     selectAllCoursesWithProgress,
//     (courses: CourseViewModel[]) => courses.filter(c => c.type === CourseType.HERO)
// );
// export const selectGeneralCourses = createSelector(
//     selectAllCoursesWithProgress,
//     (courses: CourseViewModel[]) => courses.filter(c => c.type === CourseType.GENERAL)
// );
// export const selectEnrolledCourses = createSelector(
//     selectAllEnrolledCoursesWithProgress,
//     (courses: CourseViewModel[]) => courses
// );

