import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Article } from '../../models/article.model';
import { Course } from '../../models/course.model';
import { Player } from '../../models/player.model';
import { VideoGuide } from '../../models/video-guide.model';
import * as searchActions from '../actions/search.actions';

const selectId = (entity: any) => entity.id;
const selectIdPlayer = (entity: any) => entity._id;

export const videoGuidesSuggestedContentAdapter: EntityAdapter<VideoGuide> = createEntityAdapter<
    VideoGuide
>({
    selectId
});
export const playersSuggestedContentAdapter: EntityAdapter<Player> = createEntityAdapter<Player>({
    selectId: selectIdPlayer
});
export const coursesSuggestedContentAdapter: EntityAdapter<Course> = createEntityAdapter<Course>({
    selectId
});
export const articlesSuggestedContentAdapter: EntityAdapter<Article> = createEntityAdapter<Article>({
    selectId
});

export interface SearchState {
    suggestedContent: {
        videoGuides: EntityState<VideoGuide>;
        players: EntityState<Player>;
        courses: EntityState<Course>;
        articles: EntityState<Article>;
    };
    autocompleteSearchResults: { text: string; context: string }[];
}

export const searchInitialState: SearchState = {
    suggestedContent: {
        videoGuides: videoGuidesSuggestedContentAdapter.getInitialState(),
        players: playersSuggestedContentAdapter.getInitialState(),
        courses: coursesSuggestedContentAdapter.getInitialState(),
        articles: articlesSuggestedContentAdapter.getInitialState()
    },
    autocompleteSearchResults: []
};

export const searchReducer = createReducer(
    searchInitialState,
    on(searchActions.loadSuggestedContentSuccess, (state, { payload }) => ({
        ...state,
        suggestedContent: {
            ...state.suggestedContent,
            videoGuides: videoGuidesSuggestedContentAdapter.setAll(
                payload.videoGuides,
                state.suggestedContent.videoGuides
            ),
            players: playersSuggestedContentAdapter.setAll(
                payload.players,
                state.suggestedContent.players
            ),
            courses: coursesSuggestedContentAdapter.setAll(
                payload.courses,
                state.suggestedContent.courses
            ),
            articles: articlesSuggestedContentAdapter.setAll(
                payload.articles,
                state.suggestedContent.articles
            )
        }
    })),
    on(searchActions.suggestedContentReset, state => ({
        ...state,
        suggestedContent: {
            ...state.suggestedContent,
            videoGuides: videoGuidesSuggestedContentAdapter.getInitialState(),
            players: playersSuggestedContentAdapter.getInitialState(),
            courses: coursesSuggestedContentAdapter.getInitialState(),
            articles: articlesSuggestedContentAdapter.getInitialState()
        }
    })),
    on(searchActions.loadAutocompleteResultsSuccess, (state, { payload }) => ({
        ...state,
        autocompleteSearchResults: payload
    })),
    on(searchActions.autocompleteResultsReset, state => ({
        ...state,
        autocompleteSearchResults: searchInitialState.autocompleteSearchResults
    }))
    // on(videoGuidesActions.searchVideoGuidesSuccess, (state, { payload }) => ({
    //     ...state,
    //     videoGuides: videoGuidesAdapter.setAll(payload, state.videoGuides)
    // })),
    // on(videoGuidesActions.newPlaybackSuccess, (state, { payload }) => ({
    //     ...state,
    //     playbacks: playbacksAdapter.setOne(payload, state.playbacks)
    // })),
    // on(videoGuidesActions.updatePlaybackSuccess, (state, { payload }) => ({
    //     ...state,
    //     playbacks: playbacksAdapter.updateOne(
    //         { id: payload._id, changes: payload },
    //         state.playbacks
    //     )
    // }))
);

